import React, { Component } from "react"
import {Link ,StaticQuery} from "gatsby"
import GreenStar from "../images/star-green.svg"

class FeaturedNewsSection extends Component {

  render(props) {
    var landingPageRel = this.props.landinArr

      return (
          <section className="featured-news">
            <StaticQuery
                  query={graphql`
                    query FeaturedQuery {
                      allNodeNews(limit: 3, filter: {field_featured_post: {eq: true}}) {
                        edges {
                          node {
                            created
                            title
                            body {
                              value
                            }
                            path {
                              alias
                            }
                            field_featured_post
                            relationships {
                              field_media_image {
                                relationships {
                                  field_media_image {
                                    uri {
                                      url
                                    }
                                  }
                                }
                              }
                              group_content__departments_group_node_news {
                                relationships {
                                  gid {
                                    drupal_internal__id
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      allNodeLandingPage(sort: {fields: relationships___field_hero___field_title_html___value}) {
                        edges {
                          ...node__landing_pageEdgeFragment
                        }
                      }
                    }
                  `}
                  render={data => (
                    <div className="container">
                      <div className="featured-news-title">
                        <img src={GreenStar} alt="green star"/>
                        <h2 className="h5">Featured News</h2>
                      </div>
                      <div className="uk-grid-small uk-flex uk-child-width-1-3@m uk-grid-match" data-uk-grid>
                        {data.allNodeNews.edges.map((featured, index) => (
                          <div key={index}>
                            <div className=" uk-card uk-flex uk-flex-column news-card">
                              {featured.node.relationships.field_media_image !== null && featured.node.relationships.field_media_image.relationships.field_media_image.uri.url !== null ? (
                                <Link to={featured.node.path.alias} aria-label={featured.node.title + " open card"}><div className="uk-card-media-top" style={{ backgroundImage: `url(${featured.node.relationships.field_media_image.relationships.field_media_image.uri.url})` }}/></Link>
                              ) : (
                                null
                              )}
                              <div className="uk-card-header">{featured.node.featured_time}</div>
                              <div className="uk-card-body">
                                <Link to={featured.node.path.alias}><h3 className="h5">{featured.node.title}</h3></Link>
                              </div>
                              <div className="uk-card-footer">
                                {featured.node.relationships.group_content__departments_group_node_news && featured.node.relationships.group_content__departments_group_node_news.map((group,key) => (
                                    <Link key={"grouplink"+key} to={landingPageRel[group.relationships.gid.drupal_internal__id]['path']}>{landingPageRel[group.relationships.gid.drupal_internal__id]['title']}<br /></Link>
                                  ))}

                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                />
          </section>
      )

  }
}

export default FeaturedNewsSection

import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import ArrowGreen from "../images/right-green-arrow.svg"
import ArrowLeft from "../images/arrow-white-left.svg"
import AlertsSlider from "../components/alertsslider"
import SEO from "../components/seo"
import FeaturedNewsSection from "../components/featurednewssection"



class pageTemplateNews extends Component {


  render() {
    var moment = require('moment'); // require
    const currentPage = this.props.data.nodeNews
    const relatedNews = this.props.data.allNodeNews
    const allLandingPages = this.props.data.allNodeLandingPage
    var landingPage, groupIdTemp
    const alerts = this.props.data.allNodeAlert
    const meta = currentPage.relationships.field_meta_data
    var featuredNews = []
    currentPage.relationships.group_content__departments_group_node_news && currentPage.relationships.group_content__departments_group_node_news.length > 0 ? (
        groupIdTemp = currentPage.relationships.group_content__departments_group_node_news[0].relationships.gid.drupal_internal__id
    ) : (
      groupIdTemp = null
    )
    landingPage = allLandingPages.edges.filter(
      item => item.node.relationships.group_content__group_content_type_20b7e70e3cc2b !== null && item.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id === groupIdTemp
    )

    relatedNews.nodes.map((featured, index) => (
      featured.field_featured_post === true ? (
        featuredNews.push(featured)
      ) : (
        null
      )
    ))
    featuredNews = featuredNews.slice(0, 3)
    featuredNews.map(featured => {
      featured.featured_time = moment(featured.created).format("dddd, MMMM D")
      return featuredNews
    })

    var landingPageRel = []
    var landningGroup
    allLandingPages.edges && allLandingPages.edges.map((page,key) => (
      page.node.relationships.group_content__group_content_type_20b7e70e3cc2b ? (
        landningGroup = page.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id,
        landingPageRel[landningGroup] = [],
        landingPageRel[landningGroup]['title'] = page.node.relationships.field_hero.field_title_html.value,
        landingPageRel[landningGroup]['path'] = page.node.path.alias

      ) : (
        null
      )))


    return (
        <Layout >
        <SEO
            title={currentPage.title}
            metaData={meta}
            contentBody={currentPage.body}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />
          <div className="single-news">
            <section className="uk-padding-remove-top">
              {landingPage.length > 0 && landingPage[0].node.relationships.group_content__group_content_type_20b7e70e3cc2b ? (
                <AlertsSlider allAlerts={alerts.edges} groupId={landingPage[0].node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} globalPage={false} />
              ) : (
                <AlertsSlider allAlerts={alerts.edges} groupId={null} globalPage={true} />
              )}

              <div className="container uk-margin-medium-top">
                <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
                <div className="news-content-date-and-department">
                  <div className="news-content-date">{ moment(currentPage.created).format("dddd, DD MMM YYYY") }</div>
                  {landingPage.length >= 1 && landingPage[0].node.relationships.field_hero ? (
                    <div className="news-content-department"><Link to={landingPage[0].node.path.alias} >{landingPage[0].node.relationships.field_hero.field_title_html.value}</Link></div>
                  ) : (
                    <span>County News &amp; Features</span>
                  )}
                </div>

                <div className="uk-grid uk-flex" data-uk-grid>
                  <div className="uk-width-2-3@l">
                    {currentPage.relationships.field_media_image !== null && currentPage.relationships.field_media_image.relationships.field_media_image !== null && currentPage.relationships.field_media_image.relationships.field_media_image.uri.url !== null ? (
                      <div className="single-news-image-container">
                        <img alt="single news" src={currentPage.relationships.field_media_image.relationships.field_media_image.uri.url} />
                      </div>
                    ) : (
                      null
                    )}
                    {currentPage.body && currentPage.body.processed !== null ? (
                      <div className="single-news-content" dangerouslySetInnerHTML={{ __html: currentPage.body.processed }} />
                    ) : (
                      null
                    )}
                    <Link className="back-to" to="/news">All News</Link>
                  </div>
                  <div className="uk-width-1-3@l">
                    <h2 className="h5 latest-press-releases">Latest Press Releases</h2>
                    {relatedNews.nodes.map((related, index) => (
                      index < 7 ? (
                        <div key={index}>
                          <div dangerouslySetInnerHTML={{ __html: moment(related.created).format("MMMM DD, YYYY") }} />
                          <Link className="goto" to={related.path.alias}>{ related.title }<img alt="green arrow" src={ArrowGreen} /></Link>
                        </div>
                      ) : (
                        null
                      )
                    ))}
                    <div className="find-county-contact">
                      <div className="cta-box">
                        <h2><br/>FOR<span className="green"><br/>MEDIA</span><br/>INQUIRIES</h2>
                        <div className="button-wrp">
                          <a className="uk-button green-bgr" href="/department/office-communication/contact-us">Contact Info&nbsp;<img alt="arrow left" src={ArrowLeft} /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <FeaturedNewsSection landinArr={landingPageRel} />
          </div>

        </Layout>
    )
  }
}

export default pageTemplateNews



export const pageQuery = graphql`
  query($id: String!) {
    allNodeNews(filter: {id: {ne: $id}}, limit:7, sort: {fields: created, order: DESC}) {
      nodes {
        path {
          alias
        }
        title
        created
        field_featured_post
        relationships {
          field_media_image {
            relationships {
              field_media_image {
                uri {
                  url
                }
              }
            }
          }
        }
      }
    }

    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
        ...node__alertEdgeFragment
      }
    }


    allNodeLandingPage {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }
    nodeNews(id: {eq: $id}) {
      id
      title
      created
      path {
        alias
      }
      body {
        value
        processed
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
        group_content__departments_group_node_news {
          relationships {
            gid {
              drupal_internal__id
            }
          }
        }
        field_media_image {
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
  }
`
